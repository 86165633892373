.divider {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-weight: 700;
  color: white;

  &__label {
    width: 90px;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    padding-left: 20px;

    p {
      margin: 0;
      width: 100%;
      text-align: right;
      display: flex;
      justify-content: space-between;
    }
  }
  &__line {
    flex: 1;
    border-bottom: 1px solid white;
  }
}
