.main-screen {
  height: 100%;
  // background-color: #d7e9fa;
  max-width: 480px;
  margin: 0 auto;
  padding: 30px 10px;

  h1 {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
  }
  section {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;

    &.form-container {
      background-color: #d7e9fa;
    }
    &.result {
      // background-color: #7895cb;
      background-color: #6b8cc9;
    }
  }

  .error-message {
    padding: 0 40px;
    color: #ff003e;
  }

  .button-container {
    text-align: center;
    margin-top: 20px;
  }
}
