.result-item {
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  font-weight: 700;
  color: white;

  &__label {
    width: 160px;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    padding-left: 120px;

    p {
      margin: 0;
      width: 100%;
      text-align: right;
      display: flex;
      justify-content: space-between;
    }
  }
  &__content {
    flex: 1;
  }
  &__unit {
    padding-right: 20px;
  }
}
