.form-item {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &__label {
    flex: 1;
    max-width: 50px;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    padding-left: 12px;

    p {
      margin: 0;
      width: 100%;
      text-align: right;
      display: flex;
      justify-content: space-between;
    }
  }
}
